import { Element, PlanetFilterData } from '@apeiron/planet'
import { planetClassSchema } from '@src/deserialize/yup/planet'
import * as yup from 'yup'

// for "planet_filters.json"
const planetElementSchema: yup.SchemaOf<Element> = yup
  .object({
    color: yup.string().default('white'),
    image: yup.string().default(''),
    max: yup.number().default(0),
    min: yup.number().default(0),
    name: yup.string().default(''),
    value: yup.string().default(''),
    invertColor: yup.string().default('')
  })
  .from('image_path', 'image')
  .noUnknown(true)

// for "planet_filters.json"
export const planetFilterDataSchema: yup.SchemaOf<PlanetFilterData> = yup
  .object({
    planetFilterElement: yup.array().of(planetElementSchema).default([]),
    planetFilterClass: yup.array().of(planetClassSchema).default([])
  })
  .camelCase()
  .noUnknown(true)
