import { DEFAULT_ALT } from '@apeiron/temp'
import AccountSettingIcon from '@public/icons/icon-account-setting.png'
import AdeptusSVG from '@public/icons/icon-adeptus.svg'
import BattleDemoIcon from '@public/icons/icon-battle-demo.png'
import ClaimTokenIcon from '@public/icons/icon-claim-token.png'
import ExpeditionIcon from '@public/icons/icon-expedition.png'
import FavoriteIcon from '@public/icons/icon-favorite.png'
import GodiverseIcon from '@public/icons/icon-godiverse.png'
import InventoryIcon from '@public/icons/icon-inventory.png'
import LogoutSVG from '@public/icons/icon-logout.svg'
import LpStakeIcon from '@public/icons/icon-lp-stake.svg'
import MarketplaceIcon from '@public/icons/icon-marketplace.png'
import SanctuaryIcon from '@public/icons/icon-sanctuary.png'
import { ROUTE } from '@src/constants/route'
import { GIFT_CODE_ROUTE } from '@src/deprecated/giftCode/giftCode'
import { MenuItem } from '@src/types/menu'

// enum - account menu
export enum MENU_ITEM_ACTION {
  NONE = 0,
  LOGOUT
}

// enum - account menu item hidden condition
export enum MENU_HIDDEN_CONDITION {
  NONE = 0,
  NO_INVITE_CODE = 1
}

// const - account menu
export const MENU_ITEM_CONFIG: MenuItem[] = [
  {
    actionKey: MENU_ITEM_ACTION.NONE,
    alt: DEFAULT_ALT,
    altRoutes: [
      ROUTE.INVENTORY_APOSTLE_DETAIL,
      ROUTE.INVENTORY_APOSTLE_HISTORY,
      ROUTE.INVENTORY_APOSTLE_LIST,
      ROUTE.INVENTORY_BOUNTY_ACTIVE_LIST,
      ROUTE.INVENTORY_BOUNTY_CLOSED_LIST,
      ROUTE.INVENTORY_GODIVERSE_DETAIL,
      ROUTE.INVENTORY_GODIVERSE_LIST,
      ROUTE.INVENTORY_ORBITAL_TRACK_ORBITING,
      ROUTE.INVENTORY_ORBITAL_TRACK_RENTAL,
      ROUTE.INVENTORY_STAR_DETAIL,
      ROUTE.INVENTORY_STAR_LIST,
      ROUTE.INVENTORY_STAR_MANAGE
    ],
    hideBy: MENU_HIDDEN_CONDITION.NONE,
    route: ROUTE.INVENTORY_PLANET_LIST,
    src: InventoryIcon,
    translationKey: 'account_menu.inventory'
  },
  {
    actionKey: MENU_ITEM_ACTION.NONE,
    alt: DEFAULT_ALT,
    altRoutes: [
      ROUTE.FAVORITE_APOSTLE_DETAIL,
      ROUTE.FAVORITE_APOSTLE_LIST,
      ROUTE.FAVORITE_GODIVERSE_DETAIL,
      ROUTE.FAVORITE_GODIVERSE_LIST,
      ROUTE.FAVORITE_STAR_DETAIL,
      ROUTE.FAVORITE_STAR_LIST,
      ROUTE.FAVORITE_STAR_MANAGE
    ],
    hideBy: MENU_HIDDEN_CONDITION.NONE,
    route: ROUTE.FAVORITE_PLANET_LIST,
    src: FavoriteIcon,
    translationKey: 'account_menu.favorite'
  },
  {
    actionKey: MENU_ITEM_ACTION.NONE,
    alt: DEFAULT_ALT,
    altRoutes: [GIFT_CODE_ROUTE as ROUTE],
    hideBy: MENU_HIDDEN_CONDITION.NONE,
    route: ROUTE.REWARD_LIST,
    src: ClaimTokenIcon,
    translationKey: 'account_menu.rewards'
  },
  {
    actionKey: MENU_ITEM_ACTION.NONE,
    alt: DEFAULT_ALT,
    altRoutes: [],
    hideBy: MENU_HIDDEN_CONDITION.NO_INVITE_CODE,
    route: ROUTE.ADEPTUS_PROGRAM,
    src: AdeptusSVG,
    translationKey: 'account_menu.adeptus'
  },
  {
    actionKey: MENU_ITEM_ACTION.NONE,
    alt: DEFAULT_ALT,
    altRoutes: [],
    hideBy: MENU_HIDDEN_CONDITION.NONE,
    route: ROUTE.ACCOUNT_SETTING,
    src: AccountSettingIcon,
    translationKey: 'account_menu.account_setting'
  },
  {
    actionKey: MENU_ITEM_ACTION.LOGOUT,
    alt: DEFAULT_ALT,
    altRoutes: [],
    hideBy: MENU_HIDDEN_CONDITION.NONE,
    route: ROUTE.NONE,
    src: LogoutSVG,
    translationKey: 'account_menu.logout'
  }
]

// const - primary navigation menu
export const NAVIGATION_ITEM_CONFIG = [
  {
    alt: DEFAULT_ALT,
    src: BattleDemoIcon,
    route: ROUTE.BATTLE_DEMO_GAME,
    altRoutes: [
      ROUTE.HOME,
      ROUTE.BATTLE_DEMO_START_GAME,
      ROUTE.BATTLE_DEMO_NEWS,
      ROUTE.BATTLE_DEMO_BATTLE_PASS,
      ROUTE.BATTLE_DEMO_SOLO_PVE,
      ROUTE.BATTLE_DEMO_GUILD_WAR_PVE,
      ROUTE.BATTLE_DEMO_GUILD_WAR_PVP,
      ROUTE.BATTLE_DEMO_ECONOMY
    ],
    translationKey: 'top_bar.game'
  },
  {
    alt: DEFAULT_ALT,
    src: MarketplaceIcon,
    route: ROUTE.MARKETPLACE_PLANET_LIST,
    altRoutes: [
      ROUTE.MARKETPLACE_APOSTLE_ACTIVITY_LIST,
      ROUTE.MARKETPLACE_APOSTLE_BOUNTY,
      ROUTE.MARKETPLACE_APOSTLE_DETAIL,
      ROUTE.MARKETPLACE_APOSTLE_LIST,
      ROUTE.MARKETPLACE_GODIVERSE_ACTIVITY_LIST,
      ROUTE.MARKETPLACE_GODIVERSE_DETAIL,
      ROUTE.MARKETPLACE_GODIVERSE_LIST,
      ROUTE.MARKETPLACE_ORBITAL_TRACK_ACTIVITY_LIST,
      ROUTE.MARKETPLACE_ORBITAL_TRACK_LIST,
      ROUTE.MARKETPLACE_PLANET_ACTIVITY_LIST,
      ROUTE.MARKETPLACE_PLANET_DETAIL,
      ROUTE.MARKETPLACE_STAR_ACTIVITY_LIST,
      ROUTE.MARKETPLACE_STAR_DETAIL,
      ROUTE.MARKETPLACE_STAR_LIST,
      ROUTE.MARKETPLACE_STAR_MANAGE
    ], // for highlight on nav bar
    translationKey: 'top_bar.marketplace'
  },
  {
    alt: DEFAULT_ALT,
    src: SanctuaryIcon,
    route: ROUTE.SANCTUARY_CONJUNCTION,
    altRoutes: [
      ROUTE.SANCTUARY_ATTACH_APOSTLE,
      ROUTE.SANCTUARY_REFORGE_APOSTLE
    ],
    translationKey: 'top_bar.sanctuary'
  },
  {
    alt: DEFAULT_ALT,
    src: GodiverseIcon,
    route: ROUTE.GODIVERSE_MANAGE_ATTACHMENT,
    altRoutes: [ROUTE.GODIVERSE_MANAGE_ORBITAL_TRACK],
    translationKey: 'top_bar.godiverse'
  },
  {
    alt: DEFAULT_ALT,
    src: ExpeditionIcon,
    route: ROUTE.EXPEDITION,
    altRoutes: [],
    translationKey: 'top_bar.expedition'
  },
  {
    alt: DEFAULT_ALT,
    src: LpStakeIcon,
    route: ROUTE.LP_STAKE,
    altRoutes: [],
    translationKey: 'top_bar.lp_stake'
  }
]
