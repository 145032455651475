import { ListingStatus } from '@apeiron/common'
import {
  ElementType,
  Planet,
  PlanetClassType,
  PlanetLite,
  PlanetSkill,
  PlanetSkillType,
  PlanetStage,
  PlanetStatus,
  RelicsToken
} from '@apeiron/planet'
import { BREED_IS_PRIMEVAL } from '@src/constants/conjunction'
import {
  PlanetLiteDeserializeMode,
  PlanetLiteDeserializeOption
} from '@src/types/deserialize'
import { add, differenceInSeconds, isAfter, sub } from 'date-fns'
import * as R from 'ramda'

export const getElementTKey = (element: ElementType): string => {
  switch (element) {
    case ElementType.Fire:
      return 'element.fire'
    case ElementType.Water:
      return 'element.water'
    case ElementType.Air:
      return 'element.air'
    case ElementType.Earth:
      return 'element.earth'
    default:
      return ''
  }
}

export const isCore = (planet: PlanetLite): boolean => {
  return planet.stage === PlanetStage.Core
}

export const isListing = (planet: Planet): boolean => {
  return planet.nftListingStatus === ListingStatus.LISTING
}

export const getRelicsTokens = (planet: PlanetLite): RelicsToken[] => {
  const maybeTokens: (RelicsToken | null)[] = planet.relicsTokens
    ? planet.relicsTokens
    : []

  return maybeTokens.filter(token => token !== null) as RelicsToken[]
}

export const getRelicSlotCount = (age: number): number => {
  if (age <= 0) {
    return 0
  } else if (age < 10) {
    return 1
  } else if (age < 100) {
    return 2
  } else if (age < 1000) {
    return 3
  } else if (age < 10000) {
    return 4
  } else if (age < 100000) {
    return 5
  } else if (age < 1000000) {
    return 6
  } else if (age < 10000000) {
    return 7
  } else if (age < 100000000) {
    return 8
  } else {
    return 9
  }
}

export const constructSkillUniqueKeyByValues = (
  type: PlanetSkillType,
  planetClass: PlanetClassType,
  geneId: number
): string => {
  return type === PlanetSkillType.Active
    ? String(planetClass * 1000 + geneId)
    : String(geneId)
}

export const constructSkillUniqueKey = (skill: PlanetSkill): string => {
  return constructSkillUniqueKeyByValues(
    skill.type,
    skill.attributes.planetClass,
    skill.geneId
  )
}

const checkIsOwner = (
  data: Record<string, any>,
  walletAddress: string | undefined
): boolean => {
  return R.pathOr('', ['owner', 'walletAddress'], data) === walletAddress
}

const checkIsStaked = R.propEq(PlanetStatus.Staked, 'status')

const checkIsOrbiting = R.propEq(PlanetStatus.StakedToStar, 'status')

const checkIsPlanet = (data: Record<string, any>): boolean => {
  return R.propEq(PlanetStage.Planet, 'stage', data)
}

const checkNotInBreedCooldown = (
  data: Record<string, any>,
  cooldown: number
): boolean => {
  const timeString = R.propOr('', 'breedAt', data) as string

  if (!R.isEmpty(timeString)) {
    const breedTime = new Date(timeString)

    const now = new Date()

    const breedSeconds = differenceInSeconds(now, breedTime)

    return breedSeconds > cooldown
  }

  return true
}

const checkIsSelling = (data: Record<string, any>): boolean => {
  return R.propOr(false, 'sale', data)
}

const checkNotInAttachCooldown = (data: Record<string, any>): boolean => {
  const trackEndCD = R.propOr(null, 'trackCanAttachAt', data) as Date | null

  if (!R.isNil(trackEndCD)) {
    const now = new Date()

    return isAfter(now, trackEndCD)
  }

  return true
}

const checkNotInMorphCooldown = (data: Record<string, any>): boolean => {
  if (checkIsPlanet(data) || checkIsSelling(data)) {
    return true
  }

  const timeString = R.propOr('', 'bornAt', data) as string

  if (!R.isEmpty(timeString)) {
    const bornTime = new Date(timeString)

    const now = new Date()

    const bornSeconds = differenceInSeconds(now, bornTime)

    return bornSeconds > 0
  }

  return true
}

const checkIsBreedable = (data: Record<string, any>): boolean => {
  const breedCount = R.propOr(0, 'breedCount', data) as number

  const breedCountMax = R.propOr(0, 'breedCountMax', data) as number

  return breedCount < breedCountMax
}

const checkIsMorphable = (data: Record<string, any>): boolean => {
  return !checkIsPlanet(data) && !checkIsSelling(data)
}

const checkIsGenZeroOnly = (
  data: Record<string, any>,
  isPrimeval: boolean
): boolean => {
  if (isPrimeval) {
    const generation = R.propOr(0, 'generation', data) as number

    return generation === 0
  }

  return true
}

export const deserializePlanetLite = (
  data: Record<string, any>,
  option: PlanetLiteDeserializeOption
): PlanetLite => {
  switch (option.mode) {
    case PlanetLiteDeserializeMode.CONJUNCTION: {
      const { cooldown = 0 } = option

      if (checkIsStaked(data)) {
        data = {
          ...data,
          bottomStatus: { translationKey: 'apeiron.common.staked' },
          disabled: true
        }
      } else if (!checkIsPlanet(data)) {
        data = {
          ...data,
          bottomStatus: { translationKey: 'planet.card.status.is_not_planet' },
          disabled: true
        }
      } else if (!checkNotInBreedCooldown(data, cooldown)) {
        const lastBreedAt = (data.breedAt || '').toString()

        const hasBreedRecord = lastBreedAt !== '1970-01-01T00:00:00Z'

        const start = hasBreedRecord ? new Date(lastBreedAt) : undefined

        const end = start ? add(start, { seconds: cooldown }) : undefined

        data = {
          ...data,
          bottomStatus: {
            translationKey: 'planet.card.status.under_breed_cooldown',
            start,
            end
          },
          disabled: true
        }
      } else if (!checkIsBreedable(data)) {
        data = {
          ...data,
          bottomStatus: {
            translationKey: 'planet.card.status.over_breed_limit'
          },
          disabled: true
        }
      } else if (
        !checkIsGenZeroOnly(
          data,
          R.propOr(BREED_IS_PRIMEVAL, 'isPrimeval', option)
        )
      ) {
        data = {
          ...data,
          bottomStatus: { translationKey: 'planet.card.status.gen0_only' },
          disabled: true
        }
      } else if (checkIsOrbiting(data)) {
        data = {
          ...data,
          bottomStatus: { translationKey: 'planet.card.status.orbiting' },
          disabled: true
        }
      }

      break
    }

    case PlanetLiteDeserializeMode.EXPEDITION:
      if (checkIsStaked(data)) {
        data = {
          ...data,
          bottomStatus: {
            translationKey: 'apeiron.common.staked',
            disabled: true
          }
        }
      } else if (checkIsOrbiting(data)) {
        data = {
          ...data,
          bottomStatus: { translationKey: 'planet.card.status.orbiting' },
          disabled: true
        }
      }

      break

    case PlanetLiteDeserializeMode.EMPORIUM: {
      const { walletAddress } = option

      if (checkIsOwner(data, walletAddress)) {
        // any check case
      }

      break
    }

    case PlanetLiteDeserializeMode.INVENTORY_AND_FAVORITE: {
      if (checkIsStaked(data)) {
        data = {
          ...data,
          bottomStatus: { translationKey: 'apeiron.common.staked' }
        }
      } else if (checkIsOrbiting(data)) {
        data = {
          ...data,
          bottomStatus: { translationKey: 'planet.card.status.orbiting' }
        }
      } else if (!checkNotInMorphCooldown(data)) {
        const canMorphAt = (data.bornAt || '').toString()

        const breedAt = (data.planetCreateTime || '').toString()

        data = {
          ...data,
          bottomStatus: {
            translationKey: 'planet.card.status.under_morph_cooldown',
            start: new Date(breedAt),
            end: new Date(canMorphAt)
          }
        }
      } else if (checkIsMorphable(data)) {
        data = {
          ...data,
          bottomStatus: { translationKey: 'planet.card.status.ready_to_morph' }
        }
      }

      break
    }

    case PlanetLiteDeserializeMode.ATTACH_TO_ORBIT: {
      const { cooldown = 0 } = option

      if (checkIsStaked(data)) {
        data = {
          ...data,
          bottomStatus: { translationKey: 'apeiron.common.staked' },
          disabled: true
        }
      } else if (checkIsOrbiting(data)) {
        data = {
          ...data,
          bottomStatus: { translationKey: 'planet.card.status.orbiting' },
          disabled: true
        }
      } else if (!checkNotInAttachCooldown(data)) {
        data = {
          ...data,
          bottomStatus: {
            translationKey: 'planet.date_progress_bar.orbital_detach.title',
            start: sub(data.trackCanAttachAt, { seconds: cooldown }),
            end: data.trackCanAttachAt
          },
          disabled: true
        }
      }

      break
    }

    default:
      data = {
        ...data,
        disabled: false
      }

      break
  }

  return data as unknown as PlanetLite
}
