import { ConstellationInFilter, ConstellationTypeID } from '@apeiron/star'
import { createStrictContext } from '@apeiron/temp'
import useFetchConstellationJson from '@src/hooks/json/useFetchConstellationJson'
import * as R from 'ramda'
import { FC, ReactNode, useCallback, useMemo } from 'react'

const [ContextProvider, useConstellationsContext] =
  createStrictContext<ConstellationsContextValue>('Constellations')

export { useConstellationsContext }

export const ConstellationsProvider: FC<Props> = (props: Props) => {
  const { children } = props

  const { data: constellationData } = useFetchConstellationJson()

  const sortConstellations = useCallback(
    (constellations: ConstellationInFilter[]): ConstellationInFilter[] => {
      const sort = R.sortWith<ConstellationInFilter>([
        R.ascend(R.path<any>(['galaxy', 'name'])),
        R.ascend(R.path<any>(['type', 'name'])),
        R.ascend(R.prop('name'))
      ])

      return sort(constellations as ConstellationInFilter[])
    },
    []
  )

  const allConstellations = useMemo(
    () => sortConstellations(constellationData || []),
    [constellationData, sortConstellations]
  )

  const searchConstellations = useCallback(
    (params: SearchParams): ConstellationInFilter[] => {
      if (R.has('types', params)) {
        const { maxStarCount, minStarCount, types } =
          params as SearchByPopupFilterParams

        const result: ConstellationInFilter[] = R.filter(
          (constellation: ConstellationInFilter): boolean => {
            const passMinStarCount =
              R.isNil(minStarCount) ||
              constellation.numberOfStars >= minStarCount

            const passMaxStarCount =
              R.isNil(maxStarCount) ||
              constellation.numberOfStars <= maxStarCount

            const passType =
              R.isEmpty(types) || R.includes(constellation.type.id, types)

            return passMinStarCount && passMaxStarCount && passType
          },
          allConstellations
        )

        return sortConstellations(result)
      } else if (R.has('uniqueIds', params)) {
        const { uniqueIds } = params as SearchByUniqueIdParams

        const splittedValues = R.map(R.split('_'), uniqueIds) // ['1_1_1', '1_2_2'] >>> [[1,1,1],[1,2,2]]

        const result: ConstellationInFilter[] = R.filter(
          (constellation: ConstellationInFilter): boolean => {
            const mappedKeys = R.filter((splittedValue: string[]) => {
              const [galaxyId, constellationType, constellationId] =
                splittedValue

              return R.allPass([
                R.pathEq(Number(galaxyId), ['galaxy', 'id']),
                R.propEq(Number(constellationId), 'id'),
                R.pathEq(Number(constellationType), ['type', 'id'])
              ])(constellation)
            }, splittedValues)

            return !R.isEmpty(mappedKeys)
          },
          allConstellations
        )

        return sortConstellations(result)
      }

      return []
    },
    [allConstellations, sortConstellations]
  )

  const skillsContextValue = useMemo<ConstellationsContextValue>(() => {
    return {
      allConstellations,
      sortConstellations,
      searchConstellations
    }
  }, [allConstellations, sortConstellations, searchConstellations])

  return (
    <ContextProvider value={skillsContextValue}>{children}</ContextProvider>
  )
}

type SearchByUniqueIdParams = {
  uniqueIds: string[]
}

type SearchByPopupFilterParams = {
  maxStarCount: number
  minStarCount: number
  types: ConstellationTypeID[]
}

type SearchParams = SearchByUniqueIdParams | SearchByPopupFilterParams

type ConstellationsContextValue = {
  allConstellations: ConstellationInFilter[]
  sortConstellations: (
    constellations: ConstellationInFilter[]
  ) => ConstellationInFilter[]
  searchConstellations: (params: SearchParams) => ConstellationInFilter[]
}

export type Props = {
  children: ReactNode
}
