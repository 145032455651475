import { ApeironLinkButton } from '@apeiron/temp'
import { styled } from '@mui/material'
import ENV_CONFIG from '@src/config'
import { ROUTE } from '@src/constants/route'
import { ExplainConfig } from '@src/types/explain'
import queryString from 'query-string'

const getPurePath = (input: string): string => {
  const { url } = queryString.parseUrl(input)

  return url
}

const StyledApeironLink = styled(ApeironLinkButton)`
  color: #3682eb;
  line-height: 14px;
  text-decoration: underline;
  margin-bottom: 2px;
`

export const PageExplainationMapper: { [keys in ROUTE]?: ExplainConfig } = {
  [ROUTE.SANCTUARY_ATTACH_APOSTLE]: { text: 'page_info.text.attach_apostle' },
  [ROUTE.SANCTUARY_CONJUNCTION]: {
    text: 'page_info.text.conjunction',
    extraComponent: {
      lnk: <StyledApeironLink url={ENV_CONFIG.EXTERNAL_URL.DISCORD.DOODARIA} />
    }
  },
  [ROUTE.SANCTUARY_FORGE_APOSTLE]: {
    text: 'page_info.text.apostle_angelization',
    extraMobileStyle: `bottom: 210px;
    max-height: 50vh;
    `
  },
  [ROUTE.SANCTUARY_REFORGE_APOSTLE]: { text: 'page_info.text.apostle_reforge' },
  [ROUTE.EXPEDITION]: {
    text: 'page_info.text.expedition'
  },
  [ROUTE.GODIVERSE_MANAGE_ATTACHMENT]: {
    text: 'page_info.text.manage_attachment'
  },
  [ROUTE.GODIVERSE_MANAGE_ORBITAL_TRACK]: {
    text: 'page_info.text.manage_orbital_track'
  },
  [getPurePath(ROUTE.MARKETPLACE_PLANET_LIST)]: {
    text: 'page_info.text.marketplace_planet'
  },
  [getPurePath(ROUTE.MARKETPLACE_APOSTLE_LIST)]: {
    text: 'page_info.text.marketplace_apostle'
  },
  [getPurePath(ROUTE.MARKETPLACE_STAR_LIST)]: {
    text: 'page_info.text.marketplace_star'
  },
  [ROUTE.MARKETPLACE_GODIVERSE_LIST]: {
    text: 'page_info.text.marketplace_godiverse'
  },
  [ROUTE.MARKETPLACE_ORBITAL_TRACK_LIST]: {
    text: 'page_info.text.marketplace_orbital_track'
  }
}
