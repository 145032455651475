import {
  ApeironBlueButton,
  ConfirmButton,
  ConfirmPasswordTextField,
  CustomError,
  EmailOTPSender,
  INFO_POPUP_TYPE,
  InfoPopup,
  OTPCodeTextField,
  OTPType,
  PasswordTextField,
  PopupTitle,
  useAccountContext,
  useMessageContext
} from '@apeiron/temp'
import { useMktErrorHandling } from '@apeiron/wallet'
import DoneIcon from '@mui/icons-material/Done'
import {
  Box,
  Checkbox,
  FormControlLabel,
  styled,
  Typography,
  useMediaQuery
} from '@mui/material'
import ImageBindEmailDesktop from '@public/images/image-bind-email-desktop.jpg'
import ImageBindEmailMobile from '@public/images/image-bind-email-mobile.jpg'
import { ERROR_CODE } from '@src/constants/error'
import useBindAccountEmailAndPassword from '@src/hooks/graphql/useBindAccountEmailAndPassword'
import useUpdateAccountNotification from '@src/hooks/graphql/useUpdateAccountNotification'
import theme from '@src/styles/theme'
import { t } from 'i18next'
import { FC, ReactNode, useCallback, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Trans } from 'react-i18next'

const StyledButton = styled(ApeironBlueButton)`
  width: 200px;
  height: 40px;
`

const StyledInfoPopup = styled(InfoPopup)`
  height: unset;
  max-width: 1000px;
  width: 95%;

  .Custom-Content {
    display: flex;
    overflow: visible;
    ${props => props.theme.breakpoints.down('res1024')} {
      flex-direction: column;
    }
  }
`

const BindBenefitContainer = styled(Box)`
  align-items: flex-end;
  background-image: url(${ImageBindEmailDesktop.src});
  background-size: cover;
  display: flex;
  margin-top: -50px;
  max-width: 31%;
  padding: 0px 20px 10.5% 20px;
`

const BindBenefitMobileContainer = styled(Box)`
  align-items: center;
  aspect-ratio: 3.02;
  background-image: url(${ImageBindEmailMobile.src});
  background-size: cover;
  display: flex;
  max-width: 700px;
  width: calc(100% + 100px);
  ${props => props.theme.breakpoints.up('res480')} {
    padding: 0px 10px 0px 35%;
  }
  ${props => props.theme.breakpoints.down('res480')} {
    padding: 0px 5px 0px 45%;
  }
`

const BindBenefit = styled(Typography)`
  ${props => props.theme.breakpoints.up('res1024')} {
    font-size: 14px;
    line-height: 20px;
  }
  ${props => props.theme.breakpoints.between('res480', 'res1024')} {
    font-size: 16px;
    line-height: 22px;
  }
  ${props => props.theme.breakpoints.down('res480')} {
    font-size: 12px;
    line-height: 16px;
  }
  color: white;
  white-space: pre-line;
`

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 50px 50px 50px;
  gap: 20px;
`

const StyledPopupTitle = styled(PopupTitle)`
  width: 100%;
  margin-bottom: 10px;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  width: 100%;
  margin-top: -15px;
  margin-left: 0px;
`

const Label = styled(Typography)`
  display: flex;
  align-items: center;
  color: #ffffff;
  font-weight: 300;
  strong {
    margin-left: 8px;
    color: #676869;
    font-size: 12px;
    font-weight: 300;
  }
`

const StyledCheckbox = styled(Checkbox)`
  > .MuiSvgIcon-root {
    background-color: black;
    border-radius: 4px;
  }
  > .Mui-checked {
    color: black;
    :before {
      background-image: "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath"+
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")";
    }
  }
`

const BindEmailButton: FC<Props> = props => {
  const { className, customButtonContent } = props

  const [open, setOpen] = useState(false)

  const { mutate: bindAccountEmailAndPassword } =
    useBindAccountEmailAndPassword()

  const { mutate: updateAccountNotification } = useUpdateAccountNotification()

  const { setInfo } = useMessageContext()

  const { showErrorPopup } = useMktErrorHandling()

  const { setAccount } = useAccountContext()

  const { control, trigger, handleSubmit, reset } = useForm({
    mode: 'onChange'
  })

  const password = useWatch({ name: 'password', control })

  const [checked, setChecked] = useState(false)

  const mobile = useMediaQuery(theme.breakpoints.down('res1024'))

  const handleOnClick = useCallback(() => {
    setOpen(true)
  }, [])

  const handleOnClose = useCallback(() => {
    setOpen(false)
    reset()
  }, [reset])

  const handleOnChange = useCallback(() => {
    setChecked(!checked)
  }, [checked])

  const doSubmit = useCallback(
    async (data: Record<string, any>) => {
      const { email, otpCode, password } = data

      try {
        const success = await bindAccountEmailAndPassword({
          email,
          otpCode,
          password
        })

        if (!success) {
          throw new CustomError(
            'cannot bind email',
            ERROR_CODE.ACCOUNT.BIND_EMAIL_FAIL
          )
        }

        setOpen(false)
        setInfo({
          message: t('account_setting.email_and_password_bind_updated'),
          confirm: t('common.popup.ok')
        })

        const notification = {
          assetSold: true,
          gameUpdate: true,
          marketingEvent: checked
        }

        await updateAccountNotification(notification)

        setAccount(account => ({
          ...account,
          email,
          notification
        }))
      } catch (error) {
        showErrorPopup(error)
      }
    },
    [
      bindAccountEmailAndPassword,
      checked,
      setAccount,
      setInfo,
      showErrorPopup,
      updateAccountNotification
    ]
  )

  return (
    <>
      <StyledButton className={className} onClick={handleOnClick}>
        {customButtonContent || t('account_setting.setup_email_and_password')}
      </StyledButton>
      <StyledInfoPopup
        type={INFO_POPUP_TYPE.BLACK}
        open={open}
        onClose={handleOnClose}
      >
        {!mobile ? (
          <BindBenefitContainer>
            <BindBenefit>{t('account_setting.bind_email_benefit')}</BindBenefit>
          </BindBenefitContainer>
        ) : (
          <></>
        )}
        <Form onSubmit={handleSubmit(doSubmit)}>
          <StyledPopupTitle
            title={t('account_setting.setup_email_and_password')}
          >
            {mobile ? (
              <BindBenefitMobileContainer>
                <BindBenefit>
                  {t('account_setting.bind_email_benefit')}
                </BindBenefit>
              </BindBenefitMobileContainer>
            ) : (
              <></>
            )}
          </StyledPopupTitle>
          <EmailOTPSender
            name='email'
            control={control}
            otpType={OTPType.SetUpEmailAndPassword}
          />
          <OTPCodeTextField name='otpCode' control={control} />
          <PasswordTextField
            name='password'
            control={control}
            autoComplete='new-password'
          />
          <ConfirmPasswordTextField
            autoComplete='new-password'
            control={control}
            name='confirmPassword'
            password={password}
            trigger={trigger}
          />
          <StyledFormControlLabel
            control={
              <StyledCheckbox
                checked={checked}
                onChange={handleOnChange}
                checkedIcon={<DoneIcon sx={{ color: 'white' }} />}
              />
            }
            label={
              <Label>
                <Trans>
                  {t(
                    'account_setting.notification_settings_label.event_full_sentence'
                  )}
                </Trans>
              </Label>
            }
          />
          <ConfirmButton />
        </Form>
      </StyledInfoPopup>
    </>
  )
}

type Props = {
  className?: string
  customButtonContent?: ReactNode
}

export default BindEmailButton
