import { Star } from '@apeiron/star'
import { createStrictContext, MayNull, queryGetOne } from '@apeiron/temp'
import useGetStar from '@src/hooks/graphql/useGetStar'
import { useRouter } from 'next/router'
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useState
} from 'react'

const [ContextProvider, useStarContext] =
  createStrictContext<ContextProps>('Star')

export { useStarContext }

export const StarProvider: FC<Props> = (props: Props) => {
  const { children } = props

  const router = useRouter()

  const starId = queryGetOne(router.query, 'starId') as string

  const { data, loading, refetch } = useGetStar(starId)

  const [star, setStar] = useState<MayNull<Star>>(null)

  useEffect(() => {
    setStar(data)
  }, [data])

  return (
    <ContextProvider
      value={{
        loading,
        star,
        setStar,
        refetch
      }}
    >
      {children}
    </ContextProvider>
  )
}

type ContextProps = {
  loading: boolean
  star: MayNull<Star>
  setStar: Dispatch<SetStateAction<MayNull<Star>>>
  refetch: () => void
}

export type Props = {
  children?: ReactNode
}
