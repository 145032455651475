import { Element, ElementType } from '@apeiron/planet'
import { createStrictContext } from '@apeiron/temp'
import useFetchPlanetFilterJson from '@src/hooks/json/useFetchPlanetFilterJson'
import * as R from 'ramda'
import { FC, ReactNode, useCallback, useEffect, useState } from 'react'

const [ContextProvider, useElementContext] =
  createStrictContext<ElementContextValue>('Element')

export { useElementContext }
export const ElementProvider: FC<Props> = (props: Props) => {
  const { children } = props

  const { data: filterSetting } = useFetchPlanetFilterJson()

  const [elements, setElements] = useState<Element[]>([])

  const [elementTypes, setElementTypes] = useState<ElementType[]>([])

  const extractElement = useCallback(
    (type: ElementType): Element | undefined => {
      return R.find((element: Element) => element.value === type, elements)
    },
    [elements]
  )

  useEffect(() => {
    const result = R.propOr([], 'planetFilterElement', filterSetting) as any[]

    setElements(result)
    setElementTypes(R.pluck('value', result) as ElementType[])
  }, [filterSetting])

  return (
    <ContextProvider value={{ elements, elementTypes, extractElement }}>
      {children}
    </ContextProvider>
  )
}

export type ElementContextValue = {
  elements: Element[]
  elementTypes: ElementType[]
  extractElement: (type: ElementType) => Element | undefined
}

export type Props = {
  children: ReactNode
}
