import { useMessageContext } from '@apeiron/temp'
import { FC, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const ApeironNoNetworkSnackbar: FC = () => {
  const { t } = useTranslation()

  const { setSnackInfo } = useMessageContext()

  const handleOffline = useCallback(() => {
    setSnackInfo({
      message: t('common.logic.no_internet'),
      serverity: 'warning'
    })
  }, [setSnackInfo, t])

  useEffect(() => {
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('offline', handleOffline)
    }
  }, [handleOffline])

  return null
}

export default ApeironNoNetworkSnackbar
