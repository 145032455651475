import { PlanetClass, PlanetClassType } from '@apeiron/planet'
import { createStrictContext } from '@apeiron/temp'
import useFetchPlanetFilterJson from '@src/hooks/json/useFetchPlanetFilterJson'
import * as R from 'ramda'
import { FC, ReactNode, useCallback, useEffect, useState } from 'react'

const [ContextProvider, usePlanetClassContext] =
  createStrictContext<PlanetClasssContextValue>('PlanetClass')

export { usePlanetClassContext }

export const PlanetClassProvider: FC<Props> = (props: Props) => {
  const { children } = props

  const { data: filterSetting } = useFetchPlanetFilterJson()

  const [planetClasses, setPlanetClasses] = useState<PlanetClass[]>([])

  const extractPlanetClass = useCallback(
    (type: PlanetClassType): PlanetClass | undefined => {
      return R.find(
        (planetClass: PlanetClass) => planetClass.type === type,
        planetClasses
      )
    },
    [planetClasses]
  )

  useEffect(() => {
    const classes = R.propOr([], 'planetFilterClass', filterSetting) as any[]

    setPlanetClasses(classes)
  }, [filterSetting])

  return (
    <ContextProvider value={{ planetClasses, extractPlanetClass }}>
      {children}
    </ContextProvider>
  )
}

export type PlanetClasssContextValue = {
  planetClasses: PlanetClass[]
  extractPlanetClass: (type: PlanetClassType) => PlanetClass | undefined
}

export type Props = {
  children: ReactNode
}
