import { ListingStatus } from '@apeiron/common'
import { ConstellationInFilter, Star } from '@apeiron/star'
import * as R from 'ramda'

export const isListing = (star: Star): boolean => {
  return star.nftListingStatus === ListingStatus.LISTING
}

export const constructConstellationUniqueKeyByValues = (
  galayxId: number,
  constellationType: number,
  constellationId: number
): string => {
  return `${galayxId}_${constellationType}_${constellationId}`
}

export const constructConstellationUniqueKey = (
  constellation: ConstellationInFilter
): string => {
  return constructConstellationUniqueKeyByValues(
    constellation.galaxy.id,
    constellation.type.id,
    constellation.id
  )
}

export const destructConstellationUniqueKey = (
  key: string
): { galaxy: number; constellation: number; constellationType: number } => {
  const [galaxy, constellationType, constellation] = R.split('_', key)

  return {
    galaxy: Number(galaxy),
    constellation: Number(constellation),
    constellationType: Number(constellationType)
  }
}
