import { ConstellationInFilter } from '@apeiron/star'
import { FetchJsonProps, useReactQueryFetchJson } from '@apeiron/temp'
import ENV_CONFIG from '@src/config'
import { constellationInFilterSchema } from '@src/deserialize/yup/starFilter'
import * as R from 'ramda'

const useFetchConstellationJson = (props?: FetchJsonProps) => {
  const { fetchNow } = props || {}

  return useReactQueryFetchJson<ConstellationInFilter[]>({
    fetchNow,
    path: ENV_CONFIG.JSON_FILE_NAME.CONSTELLATIONS,
    deserialize: (response: any): ConstellationInFilter[] => {
      return R.isNotNil(response)
        ? R.map(data => {
            return constellationInFilterSchema.cast(data, {
              context: {
                data,
                domain: ENV_CONFIG.DOMAIN_URL.ASSET
              }
            }) as ConstellationInFilter
          }, R.values(response))
        : []
    }
  })
}

export default useFetchConstellationJson
