import { MayNull } from '@apeiron/temp'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Popover, styled } from '@mui/material'
import MyAccountDescription from '@src/components/account/menu/Description'
import MyAccountMenuList from '@src/components/account/menu/MenuList'
import { NAV_BAR_CONFIG } from '@src/constants/layout'
import { FC, MouseEvent, useCallback } from 'react'

const StyledPopover = styled(Popover)`
  // z-index of the MuiAppBar is 1100
  z-index: 1099;
`

const Container = styled(Box)`
  align-items: center;
  background-color: #151615;
  box-shadow: 0px 8px 32.4316px rgba(0, 0, 0, 0.65);
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - ${NAV_BAR_CONFIG.primary.height}px);
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: ${NAV_BAR_CONFIG.primary.height}px;
  ${props => props.theme.breakpoints.up('res1024')} {
    width: 380px;
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    width: 100%;
  }
`

const CloseDrawerButton = styled(Button)`
  color: #b1b2b2;
  font-size: 36px;
  min-width: unset;
  padding: 8px;
  position: absolute;
  right: 4px;
  top: 4px;
`

const AccountMenu: FC<Props> = props => {
  const { anchorEl, opened, onDismiss } = props

  const handleOnBackdropClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation()
      if (onDismiss) {
        onDismiss()
      }
    },
    [onDismiss]
  )

  const handleOnOverlayClick = useCallback((event: MouseEvent) => {
    event.stopPropagation()
  }, [])

  return (
    <StyledPopover
      anchorEl={anchorEl}
      open={opened}
      onClick={handleOnBackdropClick}
      onClose={onDismiss}
    >
      <Container onClick={handleOnOverlayClick}>
        <MyAccountDescription />
        {opened && (
          <CloseDrawerButton onClick={onDismiss}>
            <CloseIcon />
          </CloseDrawerButton>
        )}
        <MyAccountMenuList onClick={onDismiss} />
      </Container>
    </StyledPopover>
  )
}

type Props = {
  anchorEl: MayNull<Element>
  opened: boolean
  onDismiss?: () => void
}

export default AccountMenu
