import {
  roninConnector,
  unstableConnector,
  WalletExtensionRdns
} from '@apeiron/temp'
import ENV_CONFIG from '@src/config'
import { walletConnect } from '@wagmi/connectors'
import { createConfig, fallback, http, injected } from '@wagmi/core'
import { ronin, saigon } from '@wagmi/core/chains'

export const config = createConfig({
  chains: [ronin, saigon],
  connectors: [
    walletConnect({
      projectId: process.env.NEXT_PUBLIC_ID_WALLETCONNECT || '',
      showQrModal: false,
      disableProviderPing: true,
      metadata: {
        url: ENV_CONFIG.WEB_URL.MARKETPLACE,
        icons: [`${ENV_CONFIG.WEB_URL.MARKETPLACE}/favicon.png`],
        name: 'Apeiron Marketplace',
        description: 'Marketplace for Apeiron NFTs'
      }
    }),
    roninConnector(),
    injected({
      target: {
        id: WalletExtensionRdns.Okx,
        name: 'Okx wallet',
        provider: (window: any) => {
          if (typeof window === 'undefined') return

          return window.okxwallet
        }
      }
    })
  ],
  multiInjectedProviderDiscovery: false,
  transports: {
    [ronin.id]: fallback([
      unstableConnector(roninConnector),
      unstableConnector(injected),
      http()
    ]),
    [saigon.id]: fallback([
      unstableConnector(roninConnector),
      unstableConnector(injected),
      http()
    ])
  }
})
