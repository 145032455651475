import { createStrictContext } from '@apeiron/temp'
import React, { ReactChild, useCallback, useState } from 'react'

type IMenuContext = {
  bar: ReactChild | ReactChild[] | null
  setupBar: (bar: ReactChild | ReactChild[] | null) => void
}

const [ContextProvider, useSecondaryNavBarContext] =
  createStrictContext<IMenuContext>('SecondaryNavBar')

export { useSecondaryNavBarContext }

// pass 2nd navigation bar from page to app layer, then display it
export const SecondaryNavBarProvider = (props: Props) => {
  const { children } = props

  const [bar, setBar] = useState<ReactChild | ReactChild[] | null>(null)

  const setupBar = useCallback((bar: ReactChild | ReactChild[] | null) => {
    setBar(bar)
  }, [])

  return <ContextProvider value={{ bar, setupBar }}>{children}</ContextProvider>
}

type Props = {
  children: ReactChild | ReactChild[]
}
