import { GodiverseFilterInput, GodiverseLite } from '@apeiron/godiverse'
import { deserializeListData } from '@apeiron/temp'
import { useLazyQuery } from '@apollo/client'
import { godiverseLiteSchema } from '@src/deserialize/yup/godiverse'
import graphql from '@src/graphql/query/getGodiverses'
import * as R from 'ramda'
import * as RA from 'ramda-adjunct'
import { useCallback, useState } from 'react'

const useGetGodiverses = (): Hook => {
  const [query, { loading }] = useLazyQuery(graphql)

  const [data, setData] = useState<GodiverseLite[]>([])

  const fetch = useCallback(
    async (request: Request): Promise<Response> => {
      const { filterInput = {} } = request

      const { ownerWalletAddresses, ...filter } = filterInput

      const variables = {
        input: {
          filter: R.reject(R.isNil, filter),
          ...(RA.isNotNilOrEmpty(ownerWalletAddresses)
            ? { ownerWalletAddress: R.propOr('', '0', ownerWalletAddresses) }
            : {})
        }
      }

      const response = await query({
        variables
      })

      const godiverse = deserializeListData<GodiverseLite>(
        response,
        godiverseLiteSchema
      )

      const sorted = godiverse.sort((a: GodiverseLite, b: GodiverseLite) => {
        return Number(a.id) - Number(b.id)
      })

      setData(sorted)

      return {
        data: sorted
      }
    },
    [query]
  )

  return {
    data,
    fetch,
    loading
  }
}

type Request = {
  filterInput?: GodiverseFilterInput
  ownerWalletAddress?: string
}

type Response = {
  data: GodiverseLite[]
}

type Hook = {
  data: GodiverseLite[]
  fetch: (request: Request) => Promise<Response>
  loading: boolean
}

export default useGetGodiverses
