import 'swiper/css/effect-fade'

import {
  ApeironBlueButton,
  ChainAddressType,
  INFO_POPUP_TYPE,
  InfoPopup,
  NextImage,
  useAccountContext,
  useChainSwitcher,
  useWeb3,
  WalletType
} from '@apeiron/temp'
import WarningIcon from '@mui/icons-material/Warning'
import { Box, styled, Typography } from '@mui/material'
import ApeironSVG from '@public/icons/icon-apeiron-logo-simplified.svg'
import MissWalletPopup from '@src/components/wallet/MissWalletPopup'
import * as R from 'ramda'
import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const StyledInfoPopup = styled(InfoPopup)`
  background-color: #262829;
  border-radius: 6px;
  height: unset;
  max-height: 95%;
  max-width: 95%;
  min-width: 360px;
  width: unset;

  .Custom-Content {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
    overflow-y: auto;
    padding: 0px 50px;
    width: 100%;
  }
`

const ApeironContainer = styled(Box)`
  position: relative;
`

const ApeironIcon = styled(NextImage)`
  aspect-ratio: 1;
  width: 130px;
`

const StyledWarningIcon = styled(WarningIcon)`
  bottom: -5px;
  color: #ff9600;
  font-size: 36px;
  left: 75%;
  position: absolute;
  transform: translateX(-50%);
`

const Title = styled(Typography)`
  font-size: 22px;
  line-height: 22px;
  margin-top: 14px;
`

const Description = styled(Typography)`
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  text-align: center;
  max-width: 400px;
`

const SubmitButton = styled(ApeironBlueButton)`
  border-radius: 4px;
  font-size: 16px;
  margin: 14px 0px 50px 0px;
  padding: 0 60px;
`

const WalletWarningPopup: FC<Props> = () => {
  const {
    missWallet,
    warnNetworkOnly,
    wrongNetwork,
    setMissWallet,
    setWarnNetworkOnly,
    setWrongNetwork
  } = useAccountContext()

  const { switchNetworkAndRequestAddress } = useWeb3()

  const { loginByWallet } = useAccountContext()

  const { getChainName, getChainIdByType } = useChainSwitcher()

  const { t } = useTranslation()

  const [showPopup, setShowPopup] = useState(false)

  const switchable =
    R.isNotNil(wrongNetwork) && R.includes(wrongNetwork, [WalletType.Okx])

  const handleOnClose = useCallback(() => {
    setShowPopup(false)
    setTimeout(() => {
      setMissWallet(null)
      setWrongNetwork(null)
      setWarnNetworkOnly(false)
    }, 500)
  }, [setMissWallet, setWarnNetworkOnly, setWrongNetwork])

  const handleOnSubmit = useCallback(async () => {
    if (switchable) {
      await switchNetworkAndRequestAddress(
        wrongNetwork,
        getChainIdByType(ChainAddressType.RONIN)
      )
      await loginByWallet(wrongNetwork, ChainAddressType.RONIN)
    }

    handleOnClose()
  }, [
    switchable,
    wrongNetwork,
    handleOnClose,
    loginByWallet,
    switchNetworkAndRequestAddress,
    getChainIdByType
  ])

  useEffect(() => {
    const shouldShowPopup = R.isNotNil(wrongNetwork) || warnNetworkOnly

    if (shouldShowPopup) {
      setShowPopup(shouldShowPopup)
    }
  }, [missWallet, warnNetworkOnly, wrongNetwork])

  if (missWallet) {
    return <MissWalletPopup />
  }

  return (
    <StyledInfoPopup
      open={showPopup}
      type={INFO_POPUP_TYPE.BLACK}
      onClose={handleOnClose}
    >
      <ApeironContainer>
        <ApeironIcon src={ApeironSVG} />
        <StyledWarningIcon />
      </ApeironContainer>
      {(warnNetworkOnly || R.isNotNil(wrongNetwork)) && (
        <>
          <Title>{t('wallet.wrong_network')}</Title>
          <Description>{t('wallet.wrong_network_desc')}</Description>
        </>
      )}
      <SubmitButton onClick={handleOnSubmit}>
        {switchable
          ? t('login.wallet.switch_network', {
              chain: getChainName(wrongNetwork)
            })
          : t('common.popup.got_it')}
      </SubmitButton>
    </StyledInfoPopup>
  )
}

type Props = {}

export default WalletWarningPopup
