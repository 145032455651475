import { useAccountContext } from '@apeiron/temp'
import { useMutation } from '@apollo/client'
import gql from '@src/graphql/mutation/bindAccountEmailAndPassword'
import * as R from 'ramda'
import { useCallback, useState } from 'react'

export const deserializeResponse = R.pathOr(false, [
  'data',
  'processSetUpEmailAndPassword'
])

const useBindAccountEmailAndPassword = (): Hook => {
  const [bindAccountEmailAndPassword, { loading }] = useMutation(gql)

  const { setAccount } = useAccountContext()

  const [data, setData] = useState<Response>(false)

  const mutate = useCallback(
    async (request: Request): Promise<Response> => {
      const { email, otpCode, password } = request

      const response = await bindAccountEmailAndPassword({
        variables: {
          input: { otpCode, password }
        }
      })

      const result = deserializeResponse(response)

      if (result) {
        setAccount(account => ({ ...account, email }))
      }

      setData(result)

      return result
    },
    [bindAccountEmailAndPassword, setAccount]
  )

  return {
    mutate,
    data,
    loading
  }
}

type Request = {
  email: string
  otpCode: string
  password: string
}

type Response = boolean

type Hook = {
  mutate: (request: Request) => Promise<Response>
  data: Response
  loading: boolean
}

export default useBindAccountEmailAndPassword
