import { INFO_POPUP_TYPE, InfoPopup, useAccountContext } from '@apeiron/temp'
import { styled } from '@mui/material'
import BindWallet from '@src/components/account/settings/BindWallet'
import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

const StyledInfoPopup = styled(InfoPopup)`
  padding: 40px;
  ${props => props.theme.breakpoints.up('res1024')} {
    height: 400px;
    width: 700px;
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    width: 100vw;
    height: 100vh;
  }
`

const ApeironBindWalletPopup: FC<Props> = () => {
  const { t } = useTranslation()

  const { showBindWalletPopup, setShowBindWalletPopup } = useAccountContext()

  const handleOnClose = useCallback(() => {
    setShowBindWalletPopup(false)
  }, [setShowBindWalletPopup])

  return (
    <StyledInfoPopup
      type={INFO_POPUP_TYPE.BLACK}
      open={showBindWalletPopup}
      onClose={handleOnClose}
    >
      <BindWallet
        title={t('bind_wallet.bind_to_continue')}
        onBindSuccess={handleOnClose}
      />
    </StyledInfoPopup>
  )
}

type Props = {}

export default ApeironBindWalletPopup
