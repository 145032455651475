import { createStrictContext } from '@apeiron/temp'
import { ROUTE_HISTORY_LIMIT } from '@src/constants/route'
import { useRouter } from 'next/router'
import * as R from 'ramda'
import { ReactChild, useCallback, useEffect, useState } from 'react'

type IHistoryContext = {
  history: string[]
}

const [ContextProvider, useHistoryContext] =
  createStrictContext<IHistoryContext>('History')

export { useHistoryContext }

export const HistoryProvider = (props: Props) => {
  const { children } = props

  const router = useRouter()

  const [history, setHistory] = useState<string[]>([])

  const handleOnHistoryUpdate = useCallback(() => {
    const historyPushed = R.append(router.asPath, history)

    const historyTrimmed = R.drop(
      R.length(historyPushed) > ROUTE_HISTORY_LIMIT ? 1 : 0,
      historyPushed
    )

    setHistory(historyTrimmed)
  }, [history, router.asPath])

  useEffect(() => {
    router.events.on('routeChangeComplete', handleOnHistoryUpdate)

    return () => {
      return router.events.off('routeChangeComplete', handleOnHistoryUpdate)
    }
  }, [handleOnHistoryUpdate, router.asPath, router.events])

  return <ContextProvider value={{ history }}>{children}</ContextProvider>
}

type Props = {
  children: ReactChild | ReactChild[]
}
