import { DEFAULT_ALT } from '@apeiron/temp'
import ENV_CONFIG from '@src/config'
import { OG_TYPE, TWITTER_NAME } from '@src/constants/ogData'
import useUserAgent from '@src/hooks/common/ui/useUserAgent'
import { DefaultSeo } from 'next-seo'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

/**
 * @see https://www.npmjs.com/package/next-seo#default-seo-configuration
 */
const OgDataDefault: FC<Props> = () => {
  const { t } = useTranslation()

  const domain = ENV_CONFIG.WEB_URL.MARKETPLACE

  const { isWhatsApp } = useUserAgent()

  /**
   * @see https://stackoverflow.com/questions/25100917/showing-thumbnail-for-link-in-whatsapp-ogimage-meta-tag-doesnt-work
   */
  const image = useMemo(() => {
    return !isWhatsApp
      ? {
          url: domain + 'images/og-image-800.jpg', // TODO - suggest to use 1024 x 768 to fit most OG image platform
          width: 800,
          height: 373,
          alt: DEFAULT_ALT
        }
      : {
          url: domain + 'images/og-image-300.jpg',
          width: 300,
          height: 300,
          alt: DEFAULT_ALT
        }
  }, [domain, isWhatsApp])

  return (
    <DefaultSeo
      canonical={domain}
      description={t('head.meta.og.description')}
      // TODO - add this when we are facebook app ready
      // facebook={{
      //   appId: FACEBOOK_APP_ID
      // }}
      title={t('head.meta.title')}
      twitter={{
        cardType: 'summary_large_image',
        handle: TWITTER_NAME, // creator
        site: TWITTER_NAME
      }}
      openGraph={{
        description: t('head.meta.og.description'),
        images: [image],
        locale: 'en',
        siteName: t('head.meta.og.site_name'),
        title: t('head.meta.title'),
        type: OG_TYPE,
        url: domain
      }}
    />
  )
}

type Props = {}

export default OgDataDefault
