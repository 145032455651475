import { isUsingEnv } from '@apeiron/temp'
import ENV_CONFIG from '@src/config'
import Script from 'next/script'
import { FC } from 'react'

const NewRelicScript: FC<Props> = () => {
  const useNewRelic =
    isUsingEnv(['development', 'production']) &&
    ENV_CONFIG.NODE_ENV === 'production'

  return useNewRelic ? <Script src={'/script/newRelic.raw.js'} /> : <></>
}

type Props = {}

export default NewRelicScript
