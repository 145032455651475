import {
  ApeironImageButton,
  ApeironLinkButton,
  DEFAULT_ANIMATE_DURATION,
  MayNull,
  NextImage,
  noForwardProps
} from '@apeiron/temp'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { TabContext, TabPanel } from '@mui/lab'
import { Box, Popover, styled, Tab, Tabs, Typography } from '@mui/material'
import DiscordSVG from '@public/icons/icon-discord-invert.svg'
import InfoSVG from '@public/icons/icon-explain-info.svg'
import DiscordMessagePNG from '@public/images/image-discord-message-bubble.png'
import ApeironDraggable from '@src/components/share/apeiron/ApeironDraggable'
import ENV_CONFIG from '@src/config'
import { PageExplainationMapper } from '@src/constants/explain'
import usePath from '@src/hooks/common/router/usePath'
import { ExplainConfig } from '@src/types/explain'
import * as R from 'ramda'
import { FC, MouseEvent, SyntheticEvent, useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

const Popup = styled(Popover)`
  .MuiPopover-paper {
    background-color: white;
    border-radius: 20px;
    margin-top: -10px;
  }
`

const Container = styled(Box, noForwardProps)<StyleProps>`
  display: flex;
  flex-direction: column;
  height: 540px;
  justify-content: space-between;
  max-height: 70vh;
  width: 350px;

  ${(props: any) => props.theme.breakpoints.down('res1024')} {
    ${props => props.__extraMobileStyle}
  }
`

const BottomTab = styled(Tabs)`
  background-color: rgba(0, 0, 0, 0.1);
  height: 20%;
  min-height: 70px;
  width: 100%;
  .MuiTabs-flexContainer {
    height: 100%;
    justify-content: center;
  }
  .MuiTabs-indicator {
    display: none;
  }
`

const IconDiscord = styled(NextImage)`
  aspect-ratio: 1;
  width: 40px;
  margin: 10px;
`

const IconQuestionMark = styled(QuestionMarkIcon)`
  font-size: 40px;
  color: rgba(144, 88, 255, 1);
`

const CloseButton = styled(ApeironImageButton)`
  padding: unset;
  position: absolute;
  right: 20px;
  top: 20px;
`

const IconClose = styled(CloseRoundedIcon)`
  font-size: 46px;
  color: #9d9d9d;
  stroke: #9d9d9d;
  stroke-width: 1;
`

const IconTab = styled(Tab)`
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  transition: 0.5s;
  margin-bottom: 0px !important;
  filter: grayscale(100%) brightness(1.4);
  &.Mui-selected {
    color: black;
    filter: none;
  }
`

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
  height: 80%;
  flex: 1;
  padding: 20px 30px;
  top: 0;
`

const Title = styled(Typography)`
  text-align: left;
  font-size: 32px;
  font-weight: 700;
  color: black;
  height: 15%;
  min-height: 40px;
`

const Description = styled(Box)`
  text-align: left;
  font-size: 16px;
  line-height: 28px;
  color: black;
  max-height: 85%;
  overflow-y: auto;
`

const StyledApeironLink = styled(ApeironLinkButton)`
  color: #3682eb;
  line-height: 14px;
  text-decoration: underline;
  font-weight: 700;
  width: 100%;
  text-align: center;
`

const DiscordImage = styled(NextImage)`
  aspect-ratio: 2;
  height: 120px;
  left: -6px;
  margin: 20px auto 0px auto;
`

const InfoIcon = styled(NextImage)`
  aspect-ratio: 1;
  width: 60px;
  min-width: 60px;
`

const StyledApeironDraggable = styled(ApeironDraggable)`
  cursor: pointer;
  position: fixed;

  transition: filter ${DEFAULT_ANIMATE_DURATION}ms,
    transform ${DEFAULT_ANIMATE_DURATION}ms;
  // ? use 1100 here as primary menu is 1099
  z-index: 1100;

  :hover {
    filter: brightness(1.2);
    transform: scale(1.05);
  }
`

const ApeironPageExplainationBox: FC<Props> = () => {
  const { cleanPath } = usePath()

  const [open, setOpen] = useState(false)

  const [tabIndex, setTabIndex] = useState('0')

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [position, setPosition] = useState({
    x: 0,
    y: 0
  })

  const handleTabChange = useCallback((_: SyntheticEvent, newValue: string) => {
    setTabIndex(newValue)
  }, [])

  const { t } = useTranslation()

  const handleOnToggle = useCallback(() => {
    setOpen(!open)
  }, [open])

  const handleOnOpen = useCallback(
    (event: MouseEvent) => {
      setAnchorEl((event as MouseEvent<HTMLButtonElement>).currentTarget)
      handleOnToggle()
    },
    [handleOnToggle]
  )

  const handleOnDraggingReposition = useCallback(() => {
    if (window) {
      setPosition({
        x: window.innerWidth - 80,
        y: window.innerHeight - 80
      })
    }
  }, [])

  const pageInfo = R.propOr(
    null,
    cleanPath,
    PageExplainationMapper
  ) as MayNull<ExplainConfig>

  return pageInfo ? (
    <>
      <Popup
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        slotProps={{
          root: {
            slotProps: {
              backdrop: { onClick: handleOnToggle }
            }
          }
        }}
        open={open}
      >
        <Container __extraMobileStyle={pageInfo.extraMobileStyle}>
          <TabContext value={tabIndex}>
            <StyledTabPanel value={'0'}>
              <Title>{t('page_info.tab.about')}</Title>
              <Description>
                <Trans components={pageInfo.extraComponent}>
                  {t(pageInfo.text)}
                </Trans>
              </Description>
            </StyledTabPanel>
            <StyledTabPanel value={'1'}>
              <Title>{t('page_info.tab.help')}</Title>
              <Description>{t('page_info.text.about')}</Description>
              <DiscordImage src={DiscordMessagePNG.src} />
              <StyledApeironLink
                url={ENV_CONFIG.EXTERNAL_URL.DISCORD.DOODARIA}
                target='__blank'
              >
                {t('page_info.text.discord_support')}
              </StyledApeironLink>
            </StyledTabPanel>
            <BottomTab value={tabIndex} onChange={handleTabChange}>
              <IconTab
                icon={<IconQuestionMark />}
                value={'0'}
                label={t('page_info.tab.about')}
              />
              <IconTab
                icon={<IconDiscord src={DiscordSVG} />}
                value={'1'}
                label={t('page_info.tab.help')}
              />
            </BottomTab>
          </TabContext>
          <CloseButton
            customIcon={<IconClose />}
            hover={false}
            onClick={handleOnToggle}
          />
        </Container>
      </Popup>
      <StyledApeironDraggable
        onClick={handleOnOpen}
        onReposition={handleOnDraggingReposition}
        position={position}
        setPosition={setPosition}
      >
        <InfoIcon src={InfoSVG} />
      </StyledApeironDraggable>
    </>
  ) : (
    <></>
  )
}

type StyleProps = {
  __extraMobileStyle?: string
}

type Props = {}

export default ApeironPageExplainationBox
