const ENV_CONFIG = {
  API_URL: {
    APP_CONNECT: process.env.NEXT_PUBLIC_API_URL_APP_CONNECT,
    EXPEDITION: process.env.NEXT_PUBLIC_API_URL_EXPEDITION,
    MARKETPLACE: process.env.NEXT_PUBLIC_API_URL_MARKETPLACE
  },
  REST_API_URL: {
    MARKETPLACE: process.env.NEXT_PUBLIC_REST_API_URL_MARKETPLACE
  },
  CONTRACT_ADDRESS: {
    APEIRON_MARKETPLACE_GATEWAY_PROXY: {
      RONIN:
        process.env
          .NEXT_PUBLIC_CONTRACT_ADDR_RONIN_APEIRON_MARKETPLACE_GATEWAY_PROXY,
      POLYGON:
        process.env
          .NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_APEIRON_MARKETPLACE_GATEWAY_PROXY
    },
    BOUNTY: {
      APOSTLE: {
        POLYGON:
          process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_APOSTLE_BOUNTY_PROXY,
        RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_APOSTLE_BOUNTY_PROXY
      }
    },
    MARKET_GATEWAY_PROXY: {
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_MARKET_GATEWAY_PROXY,
      POLYGON:
        process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_MARKET_GATEWAY_PROXY
    },
    ADEPTUS: {
      POLYGON: process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_ADEPTUS,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_ADEPTUS
    },
    ANIMA: {
      POLYGON: process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_ANIMA,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_ANIMA
    },
    APOSTLE_SEASON_CALLER: {
      POLYGON:
        process.env
          .NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_APOSTLE_SEASON_MINTING_CALLER,
      RONIN:
        process.env
          .NEXT_PUBLIC_CONTRACT_ADDR_RONIN_APOSTLE_SEASON_MINTING_CALLER
    },
    APOSTLE_SEASON: {
      POLYGON:
        process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_APOSTLE_SEASON_MINTING,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_APOSTLE_SEASON_MINTING
    },
    APOSTLE_TICKET: {
      POLYGON:
        process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_APOSTLE_TICKET_MINTING,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_APOSTLE_TICKET_MINTING
    },
    APOSTLE: {
      POLYGON: process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_APOSTLE,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_APOSTLE
    },
    APOSTLE_MANAGE: {
      POLYGON:
        process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_PLANET_APOSTLE_MANAGE,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_PLANET_APOSTLE_MANAGE
    },
    APOSTLE_FORGE: {
      POLYGON:
        process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_PLANET_APOSTLE_FORGE,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_PLANET_APOSTLE_FORGE
    },
    APRS: {
      POLYGON: process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_APRS,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_APRS
    },
    THIRD_PARTY_NFT: {
      POLYGON: process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_NFT,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_NFT
    },
    BATTLE_DEMO_BURN_TIME: {
      POLYGON:
        process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_BATTLE_DEMO_BURN_TIME,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_BATTLE_DEMO_BURN_TIME
    },
    BORN: {
      POLYGON: process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_BORN,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_BORN
    },
    BREED: {
      POLYGON: process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_BREED,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_BREED
    },
    DAILY_CHECK_IN: {
      POLYGON: process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_DAILY_CHECK_IN,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_DAILY_CHECK_IN
    },
    EXPEDITION: {
      TYPE1: {
        PRIZES: {
          POLYGON:
            process.env
              .NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_EXPEDITION_PRIZES_TYPE1,
          RONIN:
            process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_EXPEDITION_PRIZES_TYPE1
        },
        STAKING_AND_KEYS: {
          POLYGON:
            process.env
              .NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_EXPEDITION_STAKING_AND_KEYS_TYPE1,
          RONIN:
            process.env
              .NEXT_PUBLIC_CONTRACT_ADDR_RONIN_EXPEDITION_STAKING_AND_KEYS_TYPE1
        },
        INFO: {
          POLYGON:
            process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_EXPEDITION_TYPE1,
          RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_EXPEDITION_TYPE1
        }
      },
      TYPE2: {
        PRIZES: {
          POLYGON:
            process.env
              .NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_EXPEDITION_PRIZES_TYPE2,
          RONIN:
            process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_EXPEDITION_PRIZES_TYPE2
        },
        STAKING_AND_KEYS: {
          POLYGON:
            process.env
              .NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_EXPEDITION_STAKING_AND_KEYS_TYPE2,
          RONIN:
            process.env
              .NEXT_PUBLIC_CONTRACT_ADDR_RONIN_EXPEDITION_STAKING_AND_KEYS_TYPE2
        },
        INFO: {
          POLYGON:
            process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_EXPEDITION_TYPE2,
          RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_EXPEDITION_TYPE2
        }
      },
      TYPE3: {
        PRIZES: {
          POLYGON:
            process.env
              .NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_EXPEDITION_PRIZES_TYPE3,
          RONIN:
            process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_EXPEDITION_PRIZES_TYPE3
        },
        STAKING_AND_KEYS: {
          POLYGON:
            process.env
              .NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_EXPEDITION_STAKING_AND_KEYS_TYPE3,
          RONIN:
            process.env
              .NEXT_PUBLIC_CONTRACT_ADDR_RONIN_EXPEDITION_STAKING_AND_KEYS_TYPE3
        },
        INFO: {
          POLYGON:
            process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_EXPEDITION_TYPE3,
          RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_EXPEDITION_TYPE3
        }
      },
      TYPE4: {
        PRIZES: {
          POLYGON:
            process.env
              .NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_EXPEDITION_PRIZES_TYPE4,
          RONIN:
            process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_EXPEDITION_PRIZES_TYPE4
        },
        STAKING_AND_KEYS: {
          POLYGON:
            process.env
              .NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_EXPEDITION_STAKING_AND_KEYS_TYPE4,
          RONIN:
            process.env
              .NEXT_PUBLIC_CONTRACT_ADDR_RONIN_EXPEDITION_STAKING_AND_KEYS_TYPE4
        },
        INFO: {
          POLYGON:
            process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_EXPEDITION_TYPE4,
          RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_EXPEDITION_TYPE4
        }
      },
      TYPE5: {
        PRIZES: {
          POLYGON:
            process.env
              .NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_EXPEDITION_PRIZES_TYPE5,
          RONIN:
            process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_EXPEDITION_PRIZES_TYPE5
        },
        STAKING_AND_KEYS: {
          POLYGON:
            process.env
              .NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_EXPEDITION_STAKING_AND_KEYS_TYPE5,
          RONIN:
            process.env
              .NEXT_PUBLIC_CONTRACT_ADDR_RONIN_EXPEDITION_STAKING_AND_KEYS_TYPE5
        },
        INFO: {
          POLYGON:
            process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_EXPEDITION_TYPE5,
          RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_EXPEDITION_TYPE5
        }
      },
      TYPE6: {
        PRIZES: {
          POLYGON:
            process.env
              .NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_EXPEDITION_PRIZES_TYPE6,
          RONIN:
            process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_EXPEDITION_PRIZES_TYPE6
        },
        STAKING_AND_KEYS: {
          POLYGON:
            process.env
              .NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_EXPEDITION_STAKING_AND_KEYS_TYPE6,
          RONIN:
            process.env
              .NEXT_PUBLIC_CONTRACT_ADDR_RONIN_EXPEDITION_STAKING_AND_KEYS_TYPE6
        },
        INFO: {
          POLYGON:
            process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_EXPEDITION_TYPE6,
          RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_EXPEDITION_TYPE6
        }
      },
      TYPE7: {
        PRIZES: {
          POLYGON:
            process.env
              .NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_EXPEDITION_PRIZES_TYPE7,
          RONIN:
            process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_EXPEDITION_PRIZES_TYPE7
        },
        STAKING_AND_KEYS: {
          POLYGON:
            process.env
              .NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_EXPEDITION_STAKING_AND_KEYS_TYPE7,
          RONIN:
            process.env
              .NEXT_PUBLIC_CONTRACT_ADDR_RONIN_EXPEDITION_STAKING_AND_KEYS_TYPE7
        },
        INFO: {
          POLYGON:
            process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_EXPEDITION_TYPE7,
          RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_EXPEDITION_TYPE7
        }
      },
      TYPE8: {
        PRIZES: {
          POLYGON:
            process.env
              .NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_EXPEDITION_PRIZES_TYPE8,
          RONIN:
            process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_EXPEDITION_PRIZES_TYPE8
        },
        STAKING_AND_KEYS: {
          POLYGON:
            process.env
              .NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_EXPEDITION_STAKING_AND_KEYS_TYPE8,
          RONIN:
            process.env
              .NEXT_PUBLIC_CONTRACT_ADDR_RONIN_EXPEDITION_STAKING_AND_KEYS_TYPE8
        },
        INFO: {
          POLYGON:
            process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_EXPEDITION_TYPE8,
          RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_EXPEDITION_TYPE8
        }
      }
    },
    FRAGMENT: {
      POLYGON: process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_FRAGMENT,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_FRAGMENT
    },
    GODIVERSE: {
      POLYGON: process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_GODIVERSE,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_GODIVERSE
    },
    MARKETPLACE_1155: {
      POLYGON: process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_MARKETPLACE_1155,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_MARKETPLACE_1155
    },
    MARKETPLACE_721: {
      POLYGON: process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_MARKETPLACE_721,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_MARKETPLACE_721
    },
    PLANET_ATTRIBUTE_MANAGER: {
      POLYGON:
        process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_PLANET_ATTRIBUTE_MANAGER,
      RONIN:
        process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_PLANET_ATTRIBUTE_MANAGER
    },
    PLANET: {
      POLYGON: process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_PLANET,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_PLANET
    },
    STAR_ATTACH: {
      POLYGON: process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_STAR_ATTACH,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_STAR_ATTACH
    },
    STAR_ORBITAL_TRACK_CALLER: {
      POLYGON:
        process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_STAR_ORBITAL_TRACK_CALLER,
      RONIN:
        process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_STAR_ORBITAL_TRACK_CALLER
    },
    STAR_ORBITAL_TRACK: {
      POLYGON: process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_STAR_ORBITAL_TRACK,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_STAR_ORBITAL_TRACK
    },
    STAR: {
      POLYGON: process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_STAR,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_STAR
    },
    TICKET: {
      ORIGIN_APOSTLE: {
        POLYGON:
          process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_APOSTLE_ORIGIN_TICKET,
        RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_APOSTLE_ORIGIN_TICKET
      },
      S2_APOSTLE: {
        POLYGON:
          process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_APOSTLE_S2_TICKET,
        RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_APOSTLE_S2_TICKET
      }
    },
    USDC: {
      POLYGON: process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_USDC,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_USDC
    },
    VESTING: {
      POLYGON: process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_VESTING,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_VESTING
    },
    VIP_STAKING: {
      POLYGON: process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_VIP_TOKEN_STAKING,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_VIP_TOKEN_STAKING
    },
    LIQUIDITY_POOL: {
      PERMISSIONED_ROUTER: {
        POLYGON:
          process.env
            .NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_LIQUIDITY_PERMISSIONED_ROUTER,
        RONIN:
          process.env
            .NEXT_PUBLIC_CONTRACT_ADDR_RONIN_LIQUIDITY_PERMISSIONED_ROUTER
      }
    },
    WETH: {
      POLYGON: process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_WETH,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_WETH
    },
    WRON: {
      POLYGON: process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_WRON,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_WRON
    },
    APRS_WRON: {
      POLYGON: process.env.NEXT_PUBLIC_CONTRACT_ADDR_POLYGON_APRS_WRON,
      RONIN: process.env.NEXT_PUBLIC_CONTRACT_ADDR_RONIN_APRS_WRON
    }
  },
  ID: {
    FINGERPRINT: process.env.NEXT_PUBLIC_ID_FINGERPRINT_API_KEY,
    FRACTAL: process.env.NEXT_PUBLIC_ID_FRACTAL,
    GEETEST: process.env.NEXT_PUBLIC_ID_GEETEST,
    GOOGLE_TAG_MANAGER: process.env.NEXT_PUBLIC_ID_GOOGLE_TAG_MANAGER,
    WALLETCONNECT: process.env.NEXT_PUBLIC_ID_WALLETCONNECT
  },
  DOMAIN_URL: {
    ASSET: process.env.NEXT_PUBLIC_URL_DOMAIN_ASSET,
    JSON: process.env.NEXT_PUBLIC_URL_DOMAIN_JSON
  },
  EMAIL_URL: {
    SUPPORT: process.env.NEXT_PUBLIC_EMAIL_SUPPORT
  },
  EXTERNAL_URL: {
    APEIRON: {
      MEDIA_KIT: `${process.env.NEXT_PUBLIC_WEB_URL_OFFICIAL}${process.env.NEXT_PUBLIC_PATH_MEDIA_KIT}`,
      PRIVACY: `${process.env.NEXT_PUBLIC_WEB_URL_OFFICIAL}${process.env.NEXT_PUBLIC_PATH_PRIVACY}`,
      TERMS: `${process.env.NEXT_PUBLIC_WEB_URL_OFFICIAL}${process.env.NEXT_PUBLIC_PATH_TERMS}`
    },
    BRANDING: {
      CHARACTER: `${process.env.NEXT_PUBLIC_WEB_URL_BRAND_WEBSITE}${process.env.NEXT_PUBLIC_PATH_CHARACTER}`
    },
    DISCORD: {
      CHECK_IN: process.env.NEXT_PUBLIC_URL_DISCORD_CHECK_IN,
      DOODARIA: process.env.NEXT_PUBLIC_URL_DISCORD_DOODARIA
    },
    LOOTRUSH: {
      APOSTLE: process.env.NEXT_PUBLIC_URL_LOOTRUSH_APOSTLE,
      PLANET: process.env.NEXT_PUBLIC_URL_LOOTRUSH_PLANET,
      STAR: process.env.NEXT_PUBLIC_URL_LOOTRUSH_STAR,
      APEIRON: process.env.NEXT_PUBLIC_URL_LOOTRUSH
    },
    OPENSEA: process.env.NEXT_PUBLIC_URL_OPENSEA,
    POLYGON: process.env.NEXT_PUBLIC_POLYGON_TRANSACTION_URL,
    MAVIS_MARKET_GODIVERSE: process.env.NEXT_PUBLIC_URL_MAVIS_MARKET_GODIVERSE,
    WALLET: {
      METAMASK: {
        CHROME: process.env.NEXT_PUBLIC_URL_INSTALL_METAMASK_ON_CHROME,
        FIREFOX: process.env.NEXT_PUBLIC_URL_INSTALL_METAMASK_ON_FIREFOX
      },
      RONIN: {
        CHROME: process.env.NEXT_PUBLIC_URL_INSTALL_RONIN_ON_CHROME,
        FIREFOX: process.env.NEXT_PUBLIC_URL_INSTALL_RONIN_ON_FIREFOX,
        SWAP_CURRENCY: {
          RON_TO_WRON: process.env.NEXT_PUBLIC_URL_RONIN_SWAP_WRON
        },
        LIQUIDITY_POOL: {
          APRS_WRON: process.env.NEXT_PUBLIC_URL_RONIN_LIQUIDITY_POOL_APRS_WRON
        },
        TUTORIAL: {
          FREE_TRANSACTION: process.env.NEXT_PUBLIC_URL_RONIN_FREE_TRANSACTION,
          OFFICIAL: process.env.NEXT_PUBLIC_URL_RONIN_WALLET,
          VRF: process.env.NEXT_PUBLIC_URL_RONIN_VRF
        },
        DOWNLOAD: process.env.NEXT_PUBLIC_URL_RONIN_WALLET
      },
      OKX: {
        DOWNLOAD: process.env.NEXT_PUBLIC_URL_OKX_WALLET
      }
    }
  },
  ROUTE: {
    SSO: process.env.NEXT_PUBLIC_PATH_SSO_CALLBACK
  },
  NODE_ENV: process.env.NODE_ENV,
  JSON_FILE_NAME: {
    ACTIVTY_FILTERS: process.env.NEXT_PUBLIC_STATIC_JSON_ACTIVITY_FILTERS,
    APOSTLE: process.env.NEXT_PUBLIC_STATIC_JSON_APOSTLE,
    APOSTLE_FILTERS: process.env.NEXT_PUBLIC_STATIC_JSON_APOSTLE_FILTERS,
    APOSTLE_TICKETS: process.env.NEXT_PUBLIC_STATIC_JSON_APOSTLE_TICKETS,
    APP_CONNECTIONS: process.env.NEXT_PUBLIC_STATIC_JSON_APP_CONNECTIONS,
    ASTRONOMICAL_FILTERS:
      process.env.NEXT_PUBLIC_STATIC_JSON_ASTRONOMICAL_FILTERS,
    BATTLE_DEMO_ABOUT: process.env.NEXT_PUBLIC_STATIC_JSON_BATTLE_DEMO_ABOUT,
    BATTLE_DEMO_FRAGMENTS:
      process.env.NEXT_PUBLIC_STATIC_JSON_BATTLE_DEMO_FRAGMENTS,
    BOUNTY_APOSTLE_FILTERS:
      process.env.NEXT_PUBLIC_STATIC_JSON_BOUNTY_APOSTLE_FILTERS,
    CONJUNCT_COST: process.env.NEXT_PUBLIC_STATIC_JSON_CONJUNCT_COST,
    CONSTELLATIONS: process.env.NEXT_PUBLIC_STATIC_JSON_CONSTELLATIONS,
    DATA_DICTIONARY: process.env.NEXT_PUBLIC_STATIC_JSON_DATA_DICTIONARY,
    DATA_PATH: process.env.NEXT_PUBLIC_STATIC_JSON_DATA_PATH,
    DOWNLOAD: process.env.NEXT_PUBLIC_STATIC_JSON_DOWNLOAD,
    EXPEDITION_REWARD_QUANTITY:
      process.env.NEXT_PUBLIC_STATIC_JSON_EXPEDITION_REWARD_QUANTITY,
    ORBIT_FILTERS: process.env.NEXT_PUBLIC_STATIC_JSON_ORBIT_FILTERS,
    PAGE_BUILDER_BEGINNERS_GUIDE_INDEX:
      process.env.NEXT_PUBLIC_STATIC_JSON_PAGE_BUILDER_BEGINNERS_GUIDE_INDEX,
    PAGE_BUILDER_BREEDING_GUIDE:
      process.env.NEXT_PUBLIC_STATIC_JSON_PAGE_BUILDER_BREEDING_GUIDE,
    PAGE_BUILDER_BUILDS_GUIDE:
      process.env.NEXT_PUBLIC_STATIC_JSON_PAGE_BUILDER_BUILDS_GUIDE,
    PAGE_BUILDER_COLLECTORS_GUIDE:
      process.env.NEXT_PUBLIC_STATIC_JSON_PAGE_BUILDER_COLLECTORS_GUIDE,
    PAGE_BUILDER_ECONOMY:
      process.env.NEXT_PUBLIC_STATIC_JSON_PAGE_BUILDER_ECONOMY,
    PAGE_BUILDER_GAMEPLAY_GUIDE:
      process.env.NEXT_PUBLIC_STATIC_JSON_PAGE_BUILDER_GAMEPLAY_GUIDE,
    PAGE_BUILDER_PLANET_SCHOLARSHIP:
      process.env.NEXT_PUBLIC_STATIC_JSON_PAGE_BUILDER_PLANET_SCHOLARSHIP,
    PLANET_FILTERS: process.env.NEXT_PUBLIC_STATIC_JSON_PLANET_FILTERS,
    PLANET_TYPE_GROUPS: process.env.NEXT_PUBLIC_STATIC_JSON_PLANET_TYPE_GROUPS,
    PRIMEVAL_LEGACY: process.env.NEXT_PUBLIC_STATIC_JSON_PRIMEVAL_LEGACY,
    SSO: process.env.NEXT_PUBLIC_STATIC_JSON_SSO,
    STAR_FILTERS: process.env.NEXT_PUBLIC_STATIC_JSON_STAR_FILTERS,
    TREASURE: process.env.NEXT_PUBLIC_STATIC_JSON_EXPEDITION_ITEMS,
    VIP_LEVELS: process.env.NEXT_PUBLIC_STATIC_JSON_VIP_LEVELS,
    VIP_PRIVILEGES: process.env.NEXT_PUBLIC_STATIC_JSON_VIP_PRIVILEGES,
    VIP_STAKE_BANNERS: process.env.NEXT_PUBLIC_STATIC_JSON_VIP_STAKE_BANNERS,
    VIP_LP_TOKEN_TUTORIAL:
      process.env.NEXT_PUBLIC_STATIC_JSON_VIP_LP_TOKEN_TUTORIAL
  },
  JSON_URL: {
    APOSTLE_IV: process.env.NEXT_PUBLIC_STATIC_JSON_APOSTLE_IV,
    APOSTLE_SKILLS: process.env.NEXT_PUBLIC_STATIC_JSON_APOSTLE_SKILLS,
    BATTLE_DEMO_CONFIG: process.env.NEXT_PUBLIC_STATIC_JSON_BATTLE_DEMO_CONFIG,
    CARD_SKILLS: process.env.NEXT_PUBLIC_STATIC_JSON_CARD_SKILLS,
    CURRENT_SEASON: process.env.NEXT_PUBLIC_STATIC_JSON_CURRENT_SEASON,
    SKILL_GLOSSARY: process.env.NEXT_PUBLIC_STATIC_JSON_SKILL_GLOSSARY
  },
  THIRD_PARTY_API_URL: {
    CAPTCHA: process.env.NEXT_PUBLIC_CAPTCHA_SERVER_URL,
    COMMUNITY_REGISTER: process.env.NEXT_PUBLIC_URL_COMMUNITY_REGISTER,
    SKY_MAVIS_RPC: process.env.NEXT_PUBLIC_URL_SKY_MAVIS_RPC
  },
  WEB_URL: {
    MARKETPLACE: process.env.NEXT_PUBLIC_WEB_URL_MARKETPLACE,
    MARKETPLACE_LEGACY: process.env.NEXT_PUBLIC_WEB_URL_MARKETPLACE_LEGACY,
    OFFICIAL: process.env.NEXT_PUBLIC_WEB_URL_OFFICIAL,
    BRAND_WEBSITE: process.env.NEXT_PUBLIC_WEB_URL_BRAND_WEBSITE,
    STAR_MINT: process.env.NEXT_PUBLIC_WEB_URL_STAR_MINT
  },
  VARIABLES: {
    ENV: process.env.NEXT_PUBLIC_ENV || 'development',
    IS_TESTNET:
      (process.env.NEXT_PUBLIC_NETWORK_TESTNET_OR_MAINNET || 'testnet') ===
      'testnet',
    NAME: process.env.NEXT_PUBLIC_APP_NAME
  }
}

export default ENV_CONFIG
