import { ERROR_CODE as LIB_ERROR_CODE } from '@apeiron/temp'
import * as R from 'ramda'

/**
 * @see https://docs.google.com/spreadsheets/d/1WDIcT05HhuX0BsjimTVk82dFrMeijB3hGnM0Id0ttJw/edit#gid=1342129073
 */

// enum - morph error code range 1200 ~ 1299
enum MORPH_ERROR_CODE {
  CANNOT_MORPH_CORE = 1200,
  CANNOT_GET_MORPH_RESPONSE,
  CANNOT_GET_MORPHED_PLANET
}

// enum - expedition error code 1300 ~ 1399
enum EXPEDITION_ERROR_CODE {
  CANNOT_CLAIM_REWARD = 1300,
  CANNOT_STAKE_EXTRA_TOKEN,
  CANNOT_UNSTAKE_EXTRA_TOKEN
}

// enum - rental error code 1800 ~ 1899
enum RENTAL_ERROR_CODE {
  FAIL_TO_ATTACH = 1800
}

// enum - adeptus error code 1800 ~ 1899
enum ADEPTUS_ERROR_CODE {
  NOT_QUALIFY_TO_CLAIM = 14001 // error code from api
}

// enum - adeptus error code 1900 ~ 1999
enum APP_CONNECT_ERROR_CODE {
  FAIL_TO_BIND_APP = 1900
}

// const - error code
export const ERROR_CODE = R.mergeDeepLeft(LIB_ERROR_CODE, {
  ADEPTUS: ADEPTUS_ERROR_CODE,
  APP: APP_CONNECT_ERROR_CODE,
  EXPEDITION: EXPEDITION_ERROR_CODE,
  MORPH: MORPH_ERROR_CODE,
  RENTAL: RENTAL_ERROR_CODE
})
