import { removeQuery } from '@apeiron/temp'
import { PAGE_CONFIG } from '@src/constants/page'
import { useRouter } from 'next/router'
import * as R from 'ramda'
import { useCallback, useMemo } from 'react'

const usePath = (): Hook => {
  const router = useRouter()

  const { asPath } = router

  const cleanPath = useMemo(() => {
    const path = removeQuery(asPath)

    /**
     * 'asPath' mismatch between client and server when tailingSlash enabled:
     * @see https://nextjs.org/docs/pages/api-reference/functions/use-router#router-object
     * */
    return path.endsWith('/') ? path : `${path}/`
  }, [asPath])

  const extactConfig = useCallback(
    (field: string) => {
      const extract = R.pipe(
        R.toPairs,
        R.filter(pair => removeQuery(R.propOr('', '0', pair)) === cleanPath),
        R.pathOr(false, [0, 1, field])
      )

      return extract(PAGE_CONFIG)
    },
    [cleanPath]
  )

  const boundMaxWidth = useMemo(
    () => extactConfig('boundMaxWidth'),
    [extactConfig]
  )

  const noIndex = useMemo(() => extactConfig('noIndex'), [extactConfig])

  const showAccountHeader = useMemo(
    () => extactConfig('accountHeader'),
    [extactConfig]
  )

  const showNavigationBar = useMemo(
    () => !extactConfig('noNavigationBar'),
    [extactConfig]
  )

  const replacePath = useCallback(
    (nextUrl: string): string => {
      const oldPaths = R.split('/', cleanPath)

      const oldQuery = R.find(oldPath => R.startsWith('?', oldPath), oldPaths)

      const oldOffset =
        (R.endsWith('/', cleanPath) ? 2 : 1) + (R.isNotNil(oldQuery) ? 1 : 0)

      const oldPathsUsing = R.isNotNil(oldQuery)
        ? R.remove(R.length(oldPaths) - 2, 1, oldPaths)
        : oldPaths

      const oldPathReplace = R.length(oldPaths) - oldOffset

      const newPaths = R.split('/', nextUrl)

      const newQuery = R.find(newPath => R.startsWith('?', newPath), newPaths)

      const newOffset =
        (R.endsWith('/', nextUrl) ? 2 : 1) + (R.isNotNil(newQuery) ? 1 : 0)

      const newPathUse = R.propOr(
        '',
        String(R.length(newPaths) - newOffset),
        newPaths
      )

      const paths = R.update(oldPathReplace, newPathUse, oldPathsUsing)

      return R.join('/', paths) + (R.isNotNil(newQuery) ? newQuery : '')
    },
    [cleanPath]
  )

  const isCurrentPath = useCallback(
    (route: string, altRoutes: string[]): boolean => {
      return (
        removeQuery(route) === cleanPath ||
        R.map(removeQuery, altRoutes).includes(cleanPath)
      )
    },
    [cleanPath]
  )

  return {
    boundMaxWidth,
    cleanPath,
    noIndex,
    showAccountHeader,
    showNavigationBar,
    replacePath,
    isCurrentPath
  }
}

type Hook = {
  boundMaxWidth: boolean
  cleanPath: string
  noIndex: boolean
  showAccountHeader: boolean
  showNavigationBar: boolean
  replacePath: (nextUrl: string) => string
  isCurrentPath: (route: string, altRoutes: string[]) => boolean
}

export default usePath
