import { createStrictContext, MayNull, useAccountContext } from '@apeiron/temp'
import { NotificationDataType, NotifyType } from '@src/constants/notification'
import useGetLatestExpeditions from '@src/hooks/graphql/useGetLatestExpeditions'
import useGetMyNotifications from '@src/hooks/graphql/useGetMyNotifications'
import {
  Notification,
  NotificationData,
  NotifyMessage
} from '@src/types/notification'
import * as R from 'ramda'
import { ReactNode, useCallback, useEffect, useState } from 'react'

type INotificationContext = {
  notification: Notification
  fetchNotifications: () => void
  getNotifyMessage: (type: NotifyType) => MayNull<NotifyMessage>
}

const [ContextProvider, useNotificationContext] =
  createStrictContext<INotificationContext>('Notification')

export { useNotificationContext }

export const NotificationProvider = (props: Props) => {
  const { children } = props

  const [notification, setNotification] = useState<Notification>({})

  const { isLoggedIn } = useAccountContext()

  const { fetch: fetchNotification } = useGetMyNotifications()

  const { fetch: fetchLatestExpeditions } = useGetLatestExpeditions()

  const setupNotification = useCallback((results: NotificationData[]) => {
    const notification = R.reduce(
      (accumulate: Notification, item: NotificationData) => {
        if (item.count > 0) {
          switch (item.type) {
            case NotificationDataType.DailyCheckIn: {
              accumulate[NotifyType.SHORTCUT_DAILY_CHECKIN] = {
                show: true,
                message: item.count
              }

              break
            }

            case NotificationDataType.Expedition: {
              accumulate[NotifyType.TOPBAR_EXPEDITION] = {
                show: true,
                message: item.count
              }

              break
            }
          }
        }

        return accumulate
      },
      {},
      results
    )

    setNotification(notification)
  }, [])

  const fetchNotifications = useCallback(async () => {
    const resultNotification = await fetchNotification({
      notificationTypes: [NotificationDataType.DailyCheckIn]
    })

    const resultExpedition = {
      type: NotificationDataType.Expedition,
      count: R.length(await fetchLatestExpeditions())
    }

    const results = R.append(resultExpedition, resultNotification)

    setupNotification(results)
  }, [fetchLatestExpeditions, fetchNotification, setupNotification])

  const getNotifyMessage = useCallback(
    (type: NotifyType): MayNull<NotifyMessage> => {
      return R.propOr(
        null,
        String(type),
        notification
      ) as MayNull<NotifyMessage>
    },
    [notification]
  )

  useEffect(() => {
    if (isLoggedIn) {
      fetchNotifications()
    }
  }, [isLoggedIn])

  return (
    <ContextProvider
      value={{
        notification,
        getNotifyMessage,
        fetchNotifications
      }}
    >
      {children}
    </ContextProvider>
  )
}

type Props = {
  children: ReactNode
}
