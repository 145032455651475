import { gql } from '@apollo/client'

export const PublicAccountFragment = gql`
  fragment PublicAccount on PublicAccount {
    avatar
    name
    tag
    walletAddress
  }
`

export const AccountFragment = gql`
  fragment Account on Account {
    id
    avatar
    name
    tag
    email
    emailNotification {
      enableAssetSold
      enableGameUpdates
      enableMarketingEvent
    }
    walletAddress
  }
`

const BattlePassFragment = gql`
  fragment BattlePass on BattlePass {
    isStartGameDemo
    booty {
      total
      used
    }
  }
`

const MintFragment = gql`
  fragment Mint on Mint {
    apostle {
      reserveSlots
      freeTotal
      freeUsed
      paidUsed
    }
  }
`

export const GameInfoFragment = gql`
  fragment GameInfo on AccountGameInfo {
    battlePass {
      ...BattlePass
    }
    mint {
      ...Mint
    }
  }

  ${BattlePassFragment}
  ${MintFragment}
`

export const InvitationFragment = gql`
  fragment Invitation on AccountInvitation {
    invitationCode
    inviteeCount
    inviterAccountID
  }
`

export const PublicInvitationFragment = gql`
  fragment PublicInvitation on PublicAccountInvitation {
    invitationCode
    inviteeCount
    inviterAccountID
  }
`

export const LoginAccountFragment = gql`
  fragment LoginAccount on LoginAccountPayload {
    account {
      ...Account
    }
    accountInvitation {
      ...Invitation
    }
    accessToken
    refreshToken
    isNewAccount
  }

  ${AccountFragment}
  ${InvitationFragment}
`

export const VipLevelFragment = gql`
  fragment VipLevel on VipLevelOutput {
    level
    progress
    nextLevelPoint
  }
`

export const OwnerFragment = gql`
  fragment Owner on Account {
    avatar
    name
    tag
    walletAddress
    createdAt
  }
`

export const PublicOwnerFragment = gql`
  fragment PublicOwner on PublicAccount {
    avatar
    name
    tag
    walletAddress
    createdAt
  }
`
