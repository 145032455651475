import {
  ApeironLinkButton,
  DEFAULT_ANIMATE_DURATION,
  IconText
} from '@apeiron/temp'
import { styled } from '@mui/material'
import LoginSVG from '@public/icons/icon-login.svg'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const Button = styled(ApeironLinkButton)`
  border-radius: 4px;
  height: 42px;
  width: 170px;
  transition: ${DEFAULT_ANIMATE_DURATION}ms background-color;
  &.MuiButtonBase-root {
    background-color: #1d66bf;
  }
`

const StyledIconText = styled(IconText)`
  width: 100px;
  height: 100%;
  .Custom-Icon {
    aspect-ratio: 1;
    width: 20px;
  }
  .Custom-Text {
    font-size: 18px;
    font-weight: 300;
  }
`

const LoginButton: FC<Props> = (props: Props) => {
  const { className, url, onClick } = props

  const { t } = useTranslation()

  return (
    <Button className={className} onClick={onClick} url={url}>
      <StyledIconText startSrc={LoginSVG} text={t('top_bar.login')} />
    </Button>
  )
}

type Props = {
  className?: string
  url?: string
  onClick?: () => void
}

export default LoginButton
