import { ApeironBlueButton } from '@apeiron/temp'
import { Box, Container, styled, Typography } from '@mui/material'
import ErrorBoundary from '@src/components/share/generic/ErrorBoundary'
import { FC, ReactNode, useCallback } from 'react'
import { FallbackProps } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

const StyledContainer = styled(Container)`
  width: 100%;
`

const InfoContainer = styled(Box)`
  align-items: flex-start;
  background-color: #333;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  margin: 100px auto;
  width: 90%;
  max-width: 500px;
  padding: 20px;
`

const Title = styled(Typography)`
  font-size: 30px;
  font-weight: 700;
`

const Subtitle = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  white-space: pre-wrap;
`

const Detail = styled(Typography)`
  background-color: #ffffff08;
  border-radius: 4px;
  color: #888;
  font-size: 12px;
  line-height: 18px;
  padding: 20px;
  white-space: pre-wrap;
  width: 100%;
`

const RefreshButton = styled(ApeironBlueButton)`
  align-self: flex-end;
  height: 45px;
  width: 150px;
`

const ApeironErrorBoundary: FC<Props> = (props: Props) => {
  const { children } = props

  const { t } = useTranslation()

  const handleOnFallbackRender = useCallback(
    (fallbackProps: FallbackProps) => {
      const { error, resetErrorBoundary } = fallbackProps

      return (
        <StyledContainer>
          <InfoContainer>
            <Title>{t('error_boundary.sorry')}</Title>
            <Subtitle>{t('error_boundary.oops')}</Subtitle>
            <Detail>
              {t('error_boundary.reason', { reason: error.message })}
            </Detail>
            <RefreshButton onClick={resetErrorBoundary}>
              {t('error_boundary.refresh')}
            </RefreshButton>
          </InfoContainer>
        </StyledContainer>
      )
    },
    [t]
  )

  return (
    <ErrorBoundary onFallbackRender={handleOnFallbackRender}>
      {children}
    </ErrorBoundary>
  )
}

type Props = {
  children?: ReactNode
}

export default ApeironErrorBoundary
