import {
  ConstellationInFilter,
  ConstellationType,
  Galaxy,
  SlotType,
  StarFilterConstellationData,
  StarFilterData,
  StarTier
} from '@apeiron/star'
import {
  constellationTypeSchema,
  starEffectSchema
} from '@src/deserialize/yup/star'
import { constructConstellationUniqueKeyByValues } from '@src/util/apeiron/star'
import * as R from 'ramda'
import * as yup from 'yup'

// for "star_filters.json"
const galaxySchema: yup.SchemaOf<Galaxy> = yup.object({
  id: yup.number().default(0),
  image: yup.string().default(''),
  name: yup.string().default('')
})

// for "star_filters.json"
const slotSchema: yup.SchemaOf<SlotType> = yup
  .object({
    decimalScale: yup.number(),
    image: yup.string().default(''),
    max: yup.object({
      field: yup.string().default(''),
      slot: yup.number().default(0)
    }),
    min: yup.object({
      field: yup.string().default(''),
      slot: yup.number().default(0)
    }),
    name: yup.string().default(''),
    slots: yup.number().default(0)
  })
  .camelCase()

const constellationFilterSchema: yup.SchemaOf<StarFilterConstellationData> = yup
  .object({
    maxStarCount: yup.number().default(0),
    minStarCount: yup.number().default(0),
    types: yup.array().of(constellationTypeSchema)
  })
  .when(['$data'], (data: Record<string, any>) => {
    return yup.object().default({
      maxStarCount: R.pathOr(
        0,
        ['star_filter_constellation', 'star_count', 'max'],
        data
      ),
      minStarCount: R.pathOr(
        0,
        ['star_filter_constellation', 'star_count', 'min'],
        data
      ),
      types: R.map((typeData: Record<string, any>) => {
        return {
          id: typeData.id,
          name: typeData.name
        }
      }, R.pathOr([], ['star_filter_constellation', 'type'], data))
    })
  })

// for "star_filters.json" / "orbit_filters.json"
export const tierSchema: yup.SchemaOf<StarTier> = yup.object({
  image: yup.string().default(''),
  level: yup.number().default(0),
  name: yup.string().default(''),
  type: yup.string().default('')
})

// for "star_filters.json"
export const starFilterDataSchema: yup.SchemaOf<StarFilterData> = yup
  .object({
    starFilterEffect: yup.array().of(starEffectSchema).default([]),
    starFilterGalaxies: yup.array().of(galaxySchema).default([]),
    starFilterSlots: yup.array().of(slotSchema).default([]),
    starFilterTiers: yup.array().of(tierSchema).default([]),
    starFilterConstellations: constellationFilterSchema
  })
  .camelCase()
  .noUnknown(true)

export const constellationFilterTypeSchema: yup.SchemaOf<ConstellationType> =
  constellationTypeSchema.when(['$data'], (data: Record<string, any>) => {
    return yup.object().default({
      id: R.propOr(0, 'constellation_type', data),
      name: R.propOr('', 'constellation_type_name', data)
    })
  })

export const constellationInFilterSchema: yup.SchemaOf<ConstellationInFilter> =
  yup
    .object({
      description: yup.string().default(''),
      digit: yup.number().default(0),
      disabled: yup.boolean().default(false),
      galaxy: galaxySchema.nullable(),
      id: yup
        .number()
        .transform(value => Number(value))
        .default(0),
      image: yup
        .string()
        .when(['$domain'], domain => {
          return yup
            .string()
            .transform((value: string): string => `${domain}${value}`)
        })
        .default(''),
      name: yup.string().default(''),
      type: constellationFilterTypeSchema,
      numberOfStars: yup.number().default(0),
      uniqueKey: yup
        .string()
        .when(['$data'], data => {
          return yup
            .string()
            .default(
              constructConstellationUniqueKeyByValues(
                data.galaxy.id,
                data.constellation_type,
                data.id
              )
            )
        })
        .default('')
    })
    .from('star_count', 'numberOfStars')
    .noUnknown(true)
